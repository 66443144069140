.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 1.5rem 0;
}

@media screen and (max-device-width: 450px) {
    .header-socials {
        display: none;
    }
}

