.home-h1 {
    font-family: var(--font-family);
    color: white;
    font-size: 50px;
}

.home-text_accent {
    color: red;
}

.home-p {
    font-family: var(--font-second);
    color: gray;
    font-size: 25px;
    max-width: 700px;
    backdrop-filter: blur(6px);
}

.home-container_text {
   flex: 1;
   margin: 2% 20% 120px 20%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 10%;
}

@media screen and (max-device-width: 850px) {
    .home-h1 {
        font-size: 40px;
    }

    .home-p {
        font-size: 23px;
        backdrop-filter: none;
    }

    .home-button {
        align-self: center;
        margin-bottom: 15%;
    }

    .home-container_text {
        margin: 5% 10% 120px 10%;
    }
}

@media screen and (max-device-width: 570px) {
    .home-h1 {
        font-size: 36px;
    }

    .home-p {
        font-size: 21px;
    }
}

@media screen and (max-device-width: 390px) {
    .home-h1 {
        font-size: 32px;
    }

    .home-p {
        font-size: 20px;
    }
}

@media screen and (max-device-height: 850px) {
    .home-button {
        margin: 0;
        padding: 0;
        margin-top: 1rem;
    }
}

@media screen and (max-device-height: 800px) {
    .home-h1 {
        font-size: 30px;
    }

    .home-p {
        font-size: 18px;
    }
}

@media screen and (max-device-height: 735px) {
    .home-h1 {
        font-size: 28px;
    }

    .home-p {
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
}
