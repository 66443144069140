.about {
    flex: 1;
    margin-bottom: 15vh; 
}

.about-details_container {
    height: auto;
}

.about .arrow {
    display: none;
}

.about .button_scroll {
    display: flex;
}

.about.show-animate .arrow {
    display: block;
}

.about.show-animate .button_scroll {
    display: none;
}

.about-main_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: space-around;
    gap: 4rem;
    margin: 4% 10% 0% 10%;
    height: auto;
}

.about-main_title {
    font-family: var(--font-family);
    color: white;
    font-size: 47px;
    max-width: 700px;
    margin: 2px;
}

.text-accent {
    color: red;
}

.about-main_text {
    font-family: var(--font-second);
    font-size: 23px;
    max-width: 500px;
    color: gray;
}

.about-count_text {
    font-family: var(--font-second);
    font-size: 0.875rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.3;
    max-width: 100px;
    font-weight: 800;
}

.about-count_number {
    font-family: var(--font-family);
    position: relative;
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

.about-countup_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1%;
}

.about-countup {
    position: relative;
    min-width: 150px;
    margin: 0 5px 0 5px;
}

.about-countup:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: gray;
}

.about-details_container {
    color: white;
    display: flex;
    flex-direction: column;
}

.about-custom_tabs {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.about-custom_tab {
    font-family: var(--font-family);
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
    padding-bottom: 2px;
    margin: 0 3px 7px 3px;
}

.about-custom_tab::after {
    content: "";
    width: 25px;
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: -1px;
    left: 0;
    transition: all 300ms;
}

.about-custom_tab.active::after {
    width: 100%;
    transition: all 300ms;
    background-color: red;
}

.about-item_title {
    font-family: var(--font-second);
    color: gray;
    font-size: 1.1rem;
}

.about-item {
    margin: 5px 0 3px 0;
}

.about-items_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.about-item_line {
    margin: 0 10px 0 10px;
}

.about-item_value {
    font-family: var(--font-second);
    font-size: 1.2rem;
    font-weight: 800;
    color: #C0C0C0;
}

.about-data_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

@media screen and (max-device-height: 900px) and (max-device-width: 1200px){
    .about {
        margin-bottom: 10vh;
    }
}

@media screen and (max-device-height: 550px) {
    .about {
        margin-bottom: 50vh;
    }
}

@media screen and (max-device-height: 450px) {
    .about {
        margin-bottom: 60vh;
    }
}

@media screen and (max-device-height: 360px) {
    .about {
        margin-bottom: 80vh;
    }
}

@media screen and (max-device-height: 300px) {
    .about {
        margin-bottom: 90vh;
    }
}

@media screen and (max-device-width: 1516px){
    .about-main_container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 5rem;
    }

    .about-main_motion_title {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10%;
    }

    .about-details_container {
        width: 100%;
        height: auto;
    }

    .arrow {
        left: auto;
        right: 4.5rem;
    }

    .about-custom_tabs {
        margin: 0;
        margin-bottom: 1rem;
    }

    .about-main_title {
        font-size: 47px;
        max-width: 850px;
    }

    .about-main_text {
        max-width: 800px;
    }

    .about-countup_container {
        justify-content: space-around;
        max-width: 800px;
    }

    .about-countup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-details_container {
        justify-content: center;
        align-items: flex-start;
        padding-left: 50%;
    }

    .about-custom_tabs {
        gap: 3rem;
        margin: 0;
        margin-bottom: 1rem;
    }

    .about-data_container {
        font-size: 35px;
    }

    .about-item_title {
        font-size: 1.3rem;
    }

    .about-item_value {
        font-size: 1.3rem;
    }
}

@media screen and (max-device-width: 1200px) {
    .about-main_title {
        font-size: 2.6rem;
        max-width: 850px;
    }

    .about-main_text {
        max-width: 700px;
        font-size: 1.5rem;
        margin-bottom: 10%;
    }

    .about-details_container {
        padding-left: 0;
        margin-left: 50%;
        margin-right: 10%;
    }

    .about-item_title {
        font-size: 1.2rem;
    }

    .about-item_value {
        font-size: 1.2rem;
    }

    .btn {
        font-size: 1rem;
    }

    .about.show-animate .arrow {
        display: none;
    }

    .about .button_scroll {
        display: none;
    }
}

@media screen and (max-device-width: 940px) {
    .about-main_title {
        font-size: 2.3rem;
        max-width: 850px;
    }

    .about-main_text {
        max-width: 660px;
        font-size: 1.3rem;
        margin-bottom: 10%;
    }
}

@media screen and (max-device-width: 850px) {
    .about-details_container {
        padding-left: 0;
        margin-left: 35%;
        margin-right: 10%;
    }

    .about-main_title {
        font-size: 1.7rem;
    }

    .about-main_text {
        font-size: 1.2rem;
        max-width: 490px;
    }

    .about-count_number {
        font-size: 1.6rem;
        align-self: flex-start;
        margin-left: 1.8rem;
     }

     .about-count_text {
        font-size: 0.8rem;
     }

     .about-custom_tab {
        font-size: 1.3rem;
    }
}

@media screen and (max-device-width: 640px) {
     .about-main_title {
        font-size: 1.6rem;
     }

     .about-main_text {
        font-size: 1.1rem;
        max-width: 420px;
     }

     .about-count_number {
        font-size: 1.5rem;
        align-self: flex-start;
        margin-left: 1.8rem;
     }

     .about-count_text {
        font-size: 0.8rem;
     }

     .about-countup {
        position: relative;
        min-width: 150px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-details_container {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .about-custom_tab {
        font-size: 1.2rem;
    }

    .about-item_title, .about-item_line, .about-item_value{
        font-size: 1.1rem;
    }

    .btn {
        padding: 0.5rem;
        font-size: 1rem;
        letter-spacing: 0.1rem;
    }
}

@media screen and (max-device-width: 585px) {
    .about-main_title {
        font-size: 2rem;
        padding-left: 1.7rem;
        max-width: 550px;
     }

     .about-main_text {
        font-size: 1.2rem;
        max-width: 380px;
     }

     .about-main_container {
        margin-top: 2.5rem;
     }

     .about-count_number {
        font-size: 1.4rem;
     }

     .about-count_text {
        font-size: 0.8rem;
     }

     .about-countup {
        min-width: 130px;
    }

    .about-custom_tab {
        font-size: 1.1rem;
    }

    .about-item_title, .about-item_line, .about-item_value{
        font-size: 1.1rem;
    }

    .btn {
        padding: 0.4rem;
        font-size: 0.9rem;
    }

    .about-main_motion_title {
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .about-countup_container {
        flex: 0.8;
    }
}

@media screen and (max-device-width: 530px) {
    .about-main_title {
        font-size: 1.8rem;
        padding-left: 1rem;
        margin: 0;
     }

     .about-main_text {
        font-size: 1.2rem;
        max-width: 340px;
     }

     .about-main_container {
        margin-top: 2.5rem;
     }

     .about-count_number {
        font-size: 1.4rem;
     }

     .about-count_text {
        font-size: 0.8rem;
     }

     .about-countup {
        min-width: 130px;
    }

    .about-custom_tab {
        font-size: 1.1rem;
    }

    .about-item_title, .about-item_line, .about-item_value{
        font-size: 1.1rem;
    }

    .about-custom_tabs {
        gap: 1.5rem;
        margin-bottom: 1rem;
    }

    .about-details_container {
        height: 60vh;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (max-device-width: 530px) and (max-device-height: 750px) {
    .about-main_title {
        font-size: 1.4rem;
    }

    .about-main_text {
        font-size: 1.2rem;
    }

    .about-countup_container {
        flex: 0.5;
    }
}

@media screen and (max-device-width: 460px) {
    .about-main_title {
        font-size: 2rem;
        padding: 0;
        margin: 0;
     }

     .about-main_text {
        font-size: 1.3rem;
        max-width: 340px;
        margin-bottom: 2rem;
     }

     .about-count_number {
        font-size: 1.5rem;
        padding: 0;
        margin: 0;
        align-self: flex-start;
        padding-left: 1rem;
        padding-bottom: 0.5rem;
     }

     .about-count_text {
        font-size: 0.9rem;
     }

     .about-countup {
        min-width: 110px;
        margin-top: 1rem;
        padding-right: 0.4rem;
    }

    .about-custom_tab {
        font-size: 1rem;
    }

    .about-item_title, .about-item_line, .about-item_value{
        font-size: 1rem;
    }

    .about-custom_tabs {
        gap: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-device-width: 390px){
    .about-main_title {
        font-size: 1.7rem;
     }

     .about-main_text {
        font-size: 1.1rem;
        margin-bottom: 1rem;
     }

     .about-count_number {
        font-size: 1.4rem;
     }

     .about-count_text {
        font-size: 0.75rem;
        padding-left: 0.4rem;
     }

     .about-countup {
        min-width: 100px;
        margin-top: 1rem;
    }

    .about-item_title, .about-item_line, .about-item_value{
        font-size: 0.9rem;
    }

    .about-custom_tab {
        font-size: 0.9rem;
    }

    .about-custom_tabs {
        gap: 0.4rem;
    }
}

@media screen and (max-device-width: 330px) {
    .about-main_title {
        font-size: 1.5rem;
     }

     .about-main_text {
        font-size: 1rem;
        margin-bottom: 1rem;
     }

     .about-count_number {
        font-size: 1.3rem;
     }

     .about-count_text {
        font-size: 0.65rem;
     }

     .about-countup {
        min-width: 90px;
        margin-top: 1rem;
    }

    .about-item_title, .about-item_line, .about-item_value{
        font-size: 1.1rem;
    }
}

@media  screen and (max-device-width: 280px) {
    .about-countup_container {
        margin: 0;
        padding: 0;
        gap: 0;
    }

    .about-countup {
        margin: 0;
        padding: 0;
        gap: 0;
    }

    .about-custom_tab {
        font-size: 1rem;
    }

    .about-data_container {
        font-size: 1rem;
    }

    .about-item_title, .about-item_line, .about-item_line {
        font-size: 1rem;
    }
}

{/* remove arrow and button_scroll_top */}
@media screen and (max-device-height: 650px) {
    .about .button_scroll {
        display: none;
    }

    .about.show-animate .arrow {
        display: none;
    }
}

@media screen and (max-device-height: 620px) and (max-device-width: 480px){
    .about-main_title {
        font-size: 1.5rem;
     }

     .about-main_text {
        font-size: 1rem;
        margin-bottom: 0.4rem;
     }

     .about-count_number {
        font-size: 1.2rem;
     }

     .about-count_text {
        font-size: 0.6rem;
     }

     .about-countup {
        min-width: 90px;
        margin-top: 1rem;
    }
}

@media screen and (max-device-height: 540px) and (max-device-width: 480px){
    .about-main_title {
        font-size: 1.4rem;
     }

     .about-main_text {
        font-size: 0.85rem;
        margin-bottom: 0.4rem;
     }

     .about-count_number {
        font-size: 1.2rem;
     }

     .about-count_text {
        font-size: 0.6rem;
     }

     .about-countup {
        min-width: 90px;
        margin-top: 1rem;
    }
}