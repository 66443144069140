.button {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
}

.box-1{
  text-decoration: none;
}

.button span {
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);  
  cursor: pointer;
  user-select: none;
  letter-spacing: 0.3rem;
  font-size: 1.4rem;
}

.btn-one {
    color: #f9f9f9;
    transition: all 0.3s;
    position: relative;
  }
  .btn-one span {
    transition: all 0.3s;
  }
  .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255, 255, 255, 1);
    border-bottom-color: rgba(255, 255, 255, 1);
    transform: scale(0.1, 1);
  }
  .btn-one:hover span {
    letter-spacing: 2px;
  }
  .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0);
  }
  .btn-one:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }

@media screen and (max-device-width: 880px) {
  .button span {
    letter-spacing: 0.2rem;
    font-size: 1.3rem;
  }
}

@media screen and (max-device-width: 685px) {
  .button span {
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-device-width: 400px) {
  .button span {
    letter-spacing: 0.1rem;
    font-size: 1.1rem;
  }
}

@media screen and (max-device-width: 360px) {
  .button span {
    letter-spacing: 0.1rem;
    font-size: 1rem;
  }
}

@media screen and (max-device-height: 640px) {
  .button span {
    font-size: 0.9rem;
  }
}


  