.swiper-container {
    height: auto;
    color: rgb(118, 107, 107);
    overflow-y: visible !important;
    font-family: var(--font-second);
    scale: (0.85);
    transition: transform 2s ease;
}

.swiper-container.show-animate {
     scale: (1);
}

.swiper-slide {
    font-size: 1.5rem;
    text-align: center;
    border: solid 1.6px rgba(255, 255, 255, 0.408);
    margin-bottom: 20px;
    transition: transform 1s ease;
    cursor: pointer;
}

.swiper-slide:hover {
    opacity: 0.7;
}

.swiper-slide.clicked {
    transform: scale(1.15);
    box-shadow: inset 0px 0px 10px red;
    border: none;
}

.swiper-slide.clicked .swiper-slide_icon {
    color: rgb(167, 13, 13);
}

.swiper-slide_icon {
    font-size: 2rem;
}

.swiper-button-next,
.swiper-button-prev {
  color: #FF0000 !important;
}

.swiper-pagination-bullet {
    background-color: white !important;
}

.swiper-pagination-bullet-active {
    background-color: red !important;
}

.swiper-pagination {
    transform: translateY(20px) !important;
}

.container {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 5vh;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(164, 155, 155, 0.2);
    background-color: rgb(21, 21, 21);
    border-radius: 10px;
    font-family: var(--font-second);
    color: white;
}

.potential {
    margin-bottom: 1rem;
}

.head {
    text-align: flex-start;
    margin-bottom: 2rem;
}

h2 {
    color: #9e8686;
    font-weight: 900;
    margin: 0;
}

.description p {
    font-size: 1.4rem;
    margin-bottom: 20px;
}

ul {
    list-style-type: circle;
    margin-left: 20px;
    font-size: 1.2rem;
}

li {
    margin-bottom: 0.8rem;
}

.more {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
}

.prednosti-right {
    font-size: 1.6rem;
    color: #9e8686;

}

.prednosti-down {
    font-size: 1.6rem;
    color: #9e8686;
    display: none;
}

.prednosti {
    display: none;
}

.more h2:hover, .prednosti-right:hover, .prednosti-down:hover{
    opacity: 0.7;
}

.prednosti.show {
    display: block;
}

.more.show .prednosti-right {
    display: none;
}

.more.show .prednosti-down {
    display: inline;
}

.more2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
}

.usluge-right {
    font-size: 1.6rem;
    color: #9e8686;

}

.usluge-down {
    font-size: 1.6rem;
    color: #9e8686;
    display: none;
}

.sve-usluge {
    display: none;
}

.more2 h2:hover, .usluge-right:hover, .usluge-down:hover{
    opacity: 0.7;
}

.sve-usluge.show {
    display: block;
}

.more2.show .usluge-right {
    display: none;
}

.more2.show .usluge-down {
    display: inline;
}

.container {
    margin-bottom: 20vh;
}

@media screen and (max-device-width: 1200px) {
    .swiper-slide {
        font-size: 1.3rem;
    }

    .description p {
        font-size: 1.3rem;
        margin-bottom: 20px;
    }
    
    ul {
        list-style-type: circle;
        margin-left: 20px;
        font-size: 1.1rem;
    }

    .more {
        font-size: 1.1rem;
    }
    
    .prednosti-right {
        font-size: 1.5rem;
    }
    
    .prednosti-down {
        font-size: 1.5rem;
    }

    .more2 {
        font-size: 1.1rem;
    }
    
    .usluge-right {
        font-size: 1.5rem;
    }
    
    .usluge-down {
        font-size: 1.5rem;
    }
}

@media screen and (max-device-width: 1000px) {
    .swiper-slide {
        font-size: 1.2rem;
    }

    .description p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
    
    ul {
        list-style-type: circle;
        margin-left: 11px;
        font-size: 1.1rem;
    }

    .more {
        font-size: 1.1rem;
    }

    .more h2 {
        font-size: 1.4rem;
    }

    .more2 h2 {
        font-size: 1.4rem;
    }

    .swiper-container.show-animate {
        scale: (0.8) !important;
   }
}

@media screen and (max-device-width: 700px) {
    .swiper-slide {
        font-size: 1rem;
        width: 130px !important;
        height: 120px !important;
        padding: 0.4rem;
        margin-bottom: 20px;
    }

    .description p {
        font-size: 1rem;
        margin-bottom: 10px;
    }
    
    ul {
        list-style-type: circle;
        margin-left: 10px;
        font-size: 1rem;
    }

    .more {
        font-size: 1rem;
    }

    .more h2 {
        font-size: 1.2rem;
    }

    .more2 h2 {
        font-size: 1.2rem;
    }

    .head {
        font-size: 0.7rem;
    }

    .swiper-container.show-animate {
        scale: (0.6);
   }
}

@media screen and (max-device-width: 400px) {
    .swiper-slide {
        font-size: 0.9rem;
        width: 110px !important;
        height: 110px !important;
        padding: 0.4rem;
    }

    .swiper-slide_icon {
        font-size: 1.8rem;
    }   
    
    .description p {
        font-size: 0.8rem;
        margin-bottom: 10px;
    }
    
    ul {
        list-style-type: circle;
        margin-left: 7px;
        font-size: 0.8rem;
    }

    .more h2 {
        font-size: 1.1rem;
    }

    .more2 h2 {
        font-size: 1.1rem;
    }

}