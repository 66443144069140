.gallery {
  width: 100%;
}
.gallery-swiper {
    width: 100%;
    height: 80%;
}

.cars-container.hide {
    display: none;
}
  
.gallery-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 180px !important;
    width: 180px !important;
    margin: 0.2rem !important;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border: solid 1px gray;
    box-shadow: 1px 1px 6px gray;
}

.gallery-slide img {
    width: 180px;
    height: 180px;
    object-fit: cover;
}

.swiper-pagination {
    position: absolute !important;
    transform: translateY(10px) !important;
}

.home-h11 {
    height: fit-content;
}

@media screen and (max-device-width: 1200px) {
    .gallery-swiper {
        height: 80vh;
    }
}

.cars-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20vh;
  }
  
  .car-img {
    flex: 1;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .car-img img {
    width: auto;
    height: 270px;
    width: 270px;
    object-fit: cover;
    border-radius: 10px;
    transition: all 0.4s ease;
  }

  .car-img img:hover {
    scale: 1.05;
    opacity: 0.3;
    box-shadow: 0px 0px 6px rgb(154, 146, 146);
  }

  .ime-vozila {
    position: absolute;
    color: white;
    font-family: var(--font-family);
    font-size: 1.3rem;
    width: 80%;
    bottom: 0;
    font-weight: 900;
    display: none;
    max-width: 250px;
    padding: 1rem;
  }

  .car-img img {
    z-index: 999;
  }

  .car-img img:hover + .ime-vozila {
    display: block;
    z-index: 0;
  }
  