.nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;
    margin-top: auto;
    z-index: 50;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 2%;

    width: 100%;
    max-width: 6rem;
    height: 90vh;
}

.nav-inner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 1.5rem;
    margin: 1rem 2rem;

    background-color: rgb(45, 43, 43);
    backdrop-filter: blur(5px);
    font-size: 1.7rem;
    border-radius: 100px;
    height: 350px;
    max-height: 100%;
}

.nav-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    text-decoration: none;
}

.nav-tooltip {
    position: absolute;
    left: auto;
    right: 50px;
    padding: 10px;
    display: none;
}

.nav-link:hover .nav-tooltip {
    display: flex;
}

.nav-tooltip_bg {
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.375rem;
    border-radius: 3px;
}

.nav-tooltip_text {
    font-family: "Helvetica";
    font-size: 18px;
    font-weight: 700;
    color: black;

    text-transform: capitalize;
}

.nav-tooltip_arrow {
    position: absolute;
    border: solid transparent;
    border-left-color: white;
    border-width: 5px;
    
    right: -10px;
    top: 10px;
}

.nav-link_icon {
    color: white;
}

.nav-link_icon:hover {
    color: red;
}

.nav-tooltip_content {
    margin: 0;
    text-align: center;
}

.text-accent .nav-link_icon{
    color: red;
}

@media screen and (max-device-width: 1200px){
    .nav-container {
        position: fixed;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        gap: 0;

        width: 100%;
        max-width: 100%;
        height: 45px;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .nav-inner_container {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 0.6em;
        padding: 1rem;
        margin: 0;
       
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
    }

    .nav-tooltip {
        right: -40px;
        bottom: 55px;
        display: none;
    }

    .nav-tooltip_arrow {        
        right: 40px;
        top: 32px;
        rotate: 90deg;
    }
}
