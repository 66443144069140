.autoo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: center;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 0;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(164, 155, 155, 0.2);
    background-color: rgb(21, 21, 21);
    border-radius: 10px;
    font-family: var(--font-second);
    color: white;
    gap: 2rem;
    margin-bottom: 30vh;
    margin-top: 5vh;
}

.auto-h2 {
    flex: 0.5;
    font-family: var(--font-family);
    color: rgb(180, 175, 175);
    font-size: 1.7rem;
    max-width: 350px;
    font-weight: 800;
    width: 100%;
}

.mySwiper {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
}

.mySlide {
    border: none;
}

.mySlide img {
    object-fit: cover;
    object-position: center;
    width: auto;
    height: 500px;
    border: none;
}

.car-tab {
    position: relative;
    flex: 2;
    width: 34%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 4rem;
    margin-left: 1.5rem;
    padding-top: 60px;
    min-width: 200px;
}

.pic-tab {
    flex: 1;
    width: 60%;
}

.car-list {
    list-style-type: none;
    flex: 7;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.car-element {
    font-family: var(--font-second);
    align-self: flex-start;
    height: auto;
    border-radius: 10px;
    transition: all 0.5s ease;
    padding: 1rem;
    margin: 0.5rem;
    margin-top: 0;
    margin-bottom: 0.3rem;
    font-size: 1.5rem;
    width: 85%;
}

.car-element:hover {
    background-color: gray;
    color: black;
    scale: 1.1;
}

.swiper-pagination {
    transform: translateY(-5px) !important;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    color: white;
    border: none;
}

.close-button:hover {
    opacity: 0.8;
}

.close-button-up {
    display: none;
}

@media screen and (max-device-width: 1000px) {
    .pic-tab {
        width: 100%;
    }
    .mySlide img {
        width: auto;
        height: 600px;
    }

    .autoo-container {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }

    .close-button-up {
        display: block;
        align-self: flex-end;
    }

    .close-button {
        display: none;
    }

    .car-tab {
        height: 100%;
        width: 70%;
        align-self: flex-start;
        padding: 1rem;
    }

    .car-list {
        padding: 0.1rem;
        margin: 0.5rem;
    }

    .auto-h2 {
        width: 100%;
        align-self: flex-start;
    }

    .mySwiper {
        width: 100%;
    }
    
}

@media screen and (max-device-width: 850px) {
    .car-element {
        padding: 0.6rem;
        margin: 0.4rem;
        font-size: 1.4rem;
    }

    .car-tab {
        align-self: center;
        margin: 0;
        padding: 0;
        height: auto;
    }
}

@media screen and (max-device-width: 700px) {
    .car-tab {
        padding: 1rem;
        gap: 0.3rem;
    }

    .pic-tab {
        height: 700px;
    }

    .car-tab {
        flex: 0;
    }

    .mySwiper {
        height: 550px;
    }

    .mySlide {
        width: 100% !important;
        padding: 0;
        margin: 0;
    }

    .mySlide img {
        height: 500px;
    }
}

@media screen and (max-device-width: 600px) {
    .mySlide img {
        height: 400px;
    }

    .mySwiper {
        height: 450px;
    }

    .auto-h2 {
        min-width: 0;
        padding: 1px;
    }
}

@media screen and (max-device-width: 500px) {
    .auto-h2 {
        font-size: 1.3rem;
        margin: 0.4rem;
        padding: 0.4rem;
    }

    .mySlide img {
        height: 350px;
    }

    .mySwiper {
        height: 390px;
    }
}

@media screen and (max-device-width: 400px) {

    .mySlide img {
        height: 300px;
    }

    .mySwiper {
        height: 340px;
    }
}