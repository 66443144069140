@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&family=Sora:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&family=Quicksand:wght@300&family=Sora:wght@700&display=swap');

:root {
  --font-family: 'Sora', sans-serif;
  --font-second: 'Quicksand', sans-serif;
}

html {
  height: 100vh;
  overflow: hidden;
}

body{
  background-color: black;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root {
    margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
