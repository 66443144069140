.btn {    
  z-index: 0;
  position: relative; 
  padding: 0.9rem 1.2rem;
  font-size: 1.2rem;
  color: var(--inv);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);  
  cursor: pointer;
  user-select: none;
  border-left: solid gray 2px;
  border-right: solid gray 2px;
  text-align: center;
  width: fit-content;
  margin-top: 35px;
}

.btn:before, .btn:after {
  content: '';
  position: absolute; 
  transition: inherit;
  z-index: -1;
}

.btn:hover {
  color: var(--def);
  transition-delay: .5s;
}

.btn:hover:before {
  transition-delay: 0s;
}

.btn:hover:after {
  background: var(--inv);
  transition-delay: .35s;
}

.from-center:before {
  top: 0;
  left: 50%;
  height: 100%;
  width: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-center:after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: var(--inv);
}

.from-center:hover:before {
  left: 0;
  width: 100%;
}

.from-center:hover:after {
  top: 0;
  height: 100%;
}

.btn-link {
  text-decoration: none;
}

body {
  --def: rgb(34, 32, 32);   
  --inv: #b6aeae;
}

