.soc-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    z-index: 999;
}

.soc-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    align-items: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
    transition: all 0.3s ease;
    background-color: transparent;

    opacity: 0.8;
}

.soc-link:hover {
    color: red;
    transform: translateY(8px);
    box-shadow: 1px 0px 10px white;
}