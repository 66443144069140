.map-container {
    flex: 1.5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    margin: 10px;
    touch-action: none;
}

.leaflet-container {
    height: 98%;
    width: 98%;
}

@media screen and (max-device-height: 700px) {
    .map-container {
        height: 60vh;
    }
}

@media screen and (max-device-height: 650px) {
    .map-container {
        margin: 0;
        margin-top: 1rem;
        padding: 0;
    }

    .map-container {
        height: 80vh;
    }
    
}

@media screen and (max-device-height: 350px) {
    .map-container {
        height: 100vh;
    }
    
}

@media screen and (max-device-width: 1200px) {
    .map-container {
        flex: 0.8;
    }
}

@media screen and (max-device-width: 860px) {
    .map-container {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
        width: 100%;
        margin: 0;
        margin-top: 1rem;
    }
    
    .leaflet-container {
        height: 40vh;
        width: 100%;
    }

    .findUs-container {
        margin-top: 1rem;
    }
}