.calc-container {
    height: 100%;
    margin-right: 140px;
    margin-left: 1rem;
    margin-bottom: 4rem;
    margin-top: 15vh;
}


.first-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.second-section {
    flex: 10;
    color: white;
}

.select-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
    width: 100%;
}

@media screen and (max-width: 800px) {
    .select-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .istrazi {
        grid-column: span 2;
    }
}

@media screen and (max-width: 500px) {
    .select-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .istrazi {
        grid-column: span 1;
    }
}

.select {
    background-color: rgb(236, 232, 232);
    border: none;
    border-radius: 4px;
    height: 40px;
    font-family: var(--font-second);
    font-size: 1rem;
    width: 100%;
}

.select:hover {
    background-color: rgb(189, 185, 185);
}

.istrazi {
    background-color: rgb(251, 0, 0);
    border: none;
    border-radius: 4px;
    height: 40px;
    width: auto;
    font-family: var(--font-second);
    color: rgb(18, 16, 16);
    font-size: 1rem;
    font-weight: 900;
    width: 100%;
}

.istrazi:hover {
    background-color: rgb(233, 89, 89);
}

.calc-container h1 {
    margin-left: 2%;
    font-family: var(--font-family);
    font-size: 2rem;
    color: white;
    margin-bottom: 0;
}
.calc-container h1 span {
    color: red;
}

.text-select-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    margin-top: 6vh;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(164, 155, 155, 0.2);
    background-color: rgb(21, 21, 21);
    border-radius: 10px;
    font-family: var(--font-second);
    color: white;
    padding-right: 1.7rem;
}

.second-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    margin-top: 6vh;
    padding: 1rem;
    box-shadow: 0 0 1px rgba(164, 155, 155, 0.2);
    background-color: rgb(21, 21, 21);
    border-radius: 10px;
    font-family: var(--font-second);
    color: white;
    padding-right: 1.7rem;
}

@media screen and (max-device-width: 1200px) {
    .calc-container {
        margin-right: 0;
    }
}

.text-broj {
    align-self: center;
    margin: 1rem;
    width: 100%;
    align-items: center;
    border-radius: 15px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}



.text-broj label {
    font-family: var(--font-second);
    font-size: 1.5rem;
    min-width: 200px;
    font-weight: 900;
}

.text-broj .about-count_number {
    font-size: 1.5rem;
}

@media screen and (max-device-width: 1200px) {
    .text-broj {
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
    }
    
}

.wrapp {
    width: 90%;
    border-radius: 10px;
    padding: 1rem;
    transform: scale(0.95);
    transition: all 1s ease;
    margin-bottom: 7vh;
}

.wrapp-pp:hover .wrapp {
    background-color: gray;
    color: black;
    transform: scale(1);
}

.wrapp-pp:hover .text-accent {
    color: black;
}

.wrapp-pp {
    width: 100%;
}

.wrapp-pp h2 {
    transition: all 2s ease;
}

.wrapp-pp:hover h2{
    transform: translateX(10%);
    font-family: var(--font-family);
    color: white;
}

@media screen and (max-device-width: 800px) {
    .select {
        font-size: 0.8rem;
    }

    .text-broj label {
        padding-left: 0.4rem;
    }

    .calc-container {
        margin-right: 0.85em;
        margin-left: 0.85rem;
        margin-top: 20vh;
    }

    .text-select-container p {
        padding: 0;
        font-size: 1.5rem;
    }

    .first-section {
        padding: 0.5rem;
    }

    select > option {
        font-size: 1rem;
    }

    .option {
        font-size: 1rem;
    }

    .second-section {
        padding: 0.4rem;
    }

    .second-section h2 {
        font-size: 1.4rem;
    }

    .wrapp {
        padding: 0;
    }

    .text-broj label {
        font-size: 1.3rem;
    }

    .text-broj .about-count_number {
        font-size: 1rem;
        margin: 0;
        margin-left: 1rem;
        padding: 0;
        align-items: center;
        align-self: center;
    }

    .text-broj {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .text-broj label {
        min-width: 10px;
    }
    
}

@media screen and (max-device-width: 500px) {
    .text-broj label {
        font-size: 1rem;
    }

    .wrapp-pp h2 {
        font-size: 1rem;
    }
}

