.kalkulator-container {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    background-image: none;
    background: radial-gradient(circle at 43% 87%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 46%, rgba(74, 71, 74, 1) 110%);
}

.wrapp-calc {
    transform: scale(0.9);
    transition: all 2s ease;
}

.wrapp-calc.show-animate {
    transform: scale(1.1);
}