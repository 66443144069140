.forma {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    padding: 2rem;
    font-family: var(--font-second);
    font-size: 1.2rem;
    width: 50%;
    margin-top: 3vh;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(164, 155, 155, 0.2);
    background-color: rgb(21, 21, 21);
    border-radius: 10px;
    font-family: var(--font-second);
    color: white;
    margin-bottom: 140px;
}

.icon1 {
    min-width: 1.3rem;
    min-height: 1.3rem;
    max-width: 1.3rem;
    max-height: 1.3rem;
    color: gray;
}

.kontakt-forma {
    width: 100%;
    transform: scale(0.85);
    transition: all 2s ease;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 30vh;
}

.kontakt-forma.show-animate {
    transform: scale(1);
}

.kontakt-forma h1 {
    margin-top: 10vh;
    align-content: center;
}

.kontakt-forma h1{
    color: white;
    font-family: var(--font-family);
}

input[type="text"] {
    font-family: var(--font-second);
    font-size: 1.1rem;
}

input[type="number"] {
    font-family: var(--font-second);
    font-size: 1.1rem;

}

textarea {
    font-family: var(--font-second);
    font-size: 1.1rem;
}

.label-input {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px
}

.label-i {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 5px
}

.label-input > input {
    width: 95%;
    height: 40px;
}

.label-input p {
    align-self: center;
    width: 95%;
    margin-bottom: 3px;
}

.para {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

textarea {
    width: 95%;
}

.select-cont {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.select-container {
    align-items: center;
}

.select {
    font-size: 1.1rem;
}

@media screen and (max-width: 800px) {
    .select-cont {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .select-cont {
        grid-template-columns: repeat(1, 1fr);
    }
}

.usluge-cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    margin: 5px
}

.form-add {
    display: none;
}

.form-add.show {
    display: inline;
}

.dodatno-vozilo {
    display: none;
}

.dodatno-vozilo.show {
    display: flex;
}

.form2-add {
    display: none;
}

.form2-add.show {
    display: inline;
}

.dodatna-usluga {
    display: none;
}

.dodatna-usluga.show {
    display: block;
}

.nova-usluga {
    margin-top: 1.7rem;
}

.but {
    width: 200px;
    height: 50px;
    font-family: var(--font-second);
    font-size: 1.2rem;
    font-weight: 900;
    margin-top: 2rem;
}

.inp:hover {
    opacity: 0.9;
}

.headingg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    margin: auto;
    width: 50%;
    padding: 2rem;
    margin-bottom: 0;
    padding-bottom: 0;
}

.headingg h1 {
    min-width: 200px;
    margin: 0;
    padding: 0;
    align-self: center;
}

@media screen and (max-device-width: 1200px) {
    .headingg {
        padding: 0;
        width: 80%;
    }

    .forma {
        width: 75%;
    }
}
