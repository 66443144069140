.galerija-container {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: scroll;
    background-image: none;
    background: radial-gradient(circle at 43% 87%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 46%, rgba(74, 71, 74, 1) 99%);
}

.wrp-galerija {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 120px;
    margin-left: 1rem;
    scale: 0.95;
    transition: all 1s ease;
}

.wrp-galerija.show-animate {
    scale: 0.98;
}

@media screen and (max-device-width: 1200px) {
    .wrp-galerija {
        margin: 0.3rem;
    }
}